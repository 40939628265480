// AlertMessage.js
import React from 'react';
import './AlertMessage.css';

const AlertMessage = ({ message, subtext, onConfirm, onCancel }) => {
  return (
    <div className="alert-overlay">
      <div className="alert-box">
        <p className="alert-message">{message}</p>
        {subtext && <p className="alert-subtext">{subtext}</p>}

        {/* Divider line */}
        <div className="alert-divider"></div>

        <div className="alert-buttons">
          {onCancel ? (
            // Two buttons for Yes/No if onCancel exists
            <>
              <button className="alert-button cancel" onClick={onCancel}>
                Non
              </button>
              <button className="alert-button confirm" onClick={onConfirm}>
                Oui
              </button>
            </>
          ) : (
            // Single centered "OK" button if onCancel is not provided
            <button className="alert-button ok" onClick={onConfirm}>
              OK
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
