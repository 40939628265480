import React from 'react';
import Contraindications from './forms/Contraindications';
import HairClass from './forms/HairClass';
import HairState from './forms/HairState';
import HairGoal from './forms/HairGoal';
import HairChallenges from './forms/HairChallenges';
import ScalpChallenges from './forms/ScalpChallenges';
import '../App.css'


const Forms = ({ step, goToNextStep, goToPrevStep, goToFilterChildAge, goToVisualConfirmation, goToOverview} ) => {
  // Define a mapping between step numbers and form components
  const stepComponents = {
    1: <Contraindications goToNextStep={goToNextStep} goToFilterChildAge={goToFilterChildAge}/>,
    2: <HairClass goToNextStep={goToNextStep} goToPrevStep={goToPrevStep}/>,
    3: <HairState goToNextStep={goToNextStep} goToPrevStep={goToPrevStep}/>,
    4: <HairGoal goToNextStep={goToNextStep} goToPrevStep={goToPrevStep}/>,
    5: <HairChallenges goToNextStep={goToNextStep} goToPrevStep={goToPrevStep}/>,
    6: <ScalpChallenges goToVisualConfirmation={goToVisualConfirmation} goToPrevStep={goToPrevStep}/>,
  };

  // Render the appropriate form based on the step number
  return stepComponents[step] || null;
};

export default Forms;