// MultiStepMenu.js

import React from 'react';

const MultiStepMenu = ({ step, numberOfSteps }) => {
  // Object to store subtitles for each step
  const stepSubtitles = {
    1: 'Caractéristiques​',
    2: 'Classe de cheveux​',
    3: 'État des cheveux​',
    4: 'Objectif cheveux​',
    5: 'Défis capillaires​',
    6: 'Défis pour le cuir chevelu​​',
  };

  // Function to get subtitle based on the step number
  const getSubtitle = (step) => {
    return stepSubtitles[step] || 'Step ' + step;
  };

  return (
    <div className="">
      <div className="subtitle">{getSubtitle(step)}</div>
      <div className="multistep-menu-container">
        <div className="multi-step-menu">
          {/* Multi-step menu */}
          <ul>
            {[...Array(numberOfSteps)].map((_, index) => (
              <li key={index + 1} className={step === index + 1 ? 'active' : ''}>
                {index + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MultiStepMenu;
