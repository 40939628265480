import React, { useState } from 'react';
import '../App.css';
import AlertMessage from './AlertMessage';


const VisualConfirmation = ({ goToOverview, goToHairSegmentation, goToForms }) => {
  // Navigate to previous page
  const [showAlert, setShowAlert] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleYes = () => {
    goToHairSegmentation();
  };

  // Navigate to next page
  const handleNo = () => {
    goToOverview();
  };

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    // setShowErrorAlert(false);
    setShowAlert(false);
  };


  return (
    <div className="form-financialProduct" style={{textAlign:'center'}}>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <div className="content" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full viewport height for vertical centering
        textAlign: 'center',
      }}>
        <h2 style={{fontSize: 'clamp(2.3rem, 2vw, 2.5rem)', width: 'auto', maxWidth: '600px', marginBottom: '20%', textAlign:'center'}}>Puis-je utiliser une photo pour vérifier visuellement vos informations capillaires ?</h2>
      </div>
      <div className='submit-container'>
        <div className="white-banner">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px', width:'100%' }}>
            <button className="request-button-fixed" style={{ backgroundColor: 'black', color: 'white', marginTop: '50px', marginLeft: '-200px', marginRight: '0px', height:'5vh', width:'30vw', borderRadius: '25px', minHeight: '50px', maxHeight: '70px', minWidth: '100px', maxWidth: '150px', bottom: '10%'}} onClick={handleYes}>Oui</button>
            <button className="request-button-fixed" style={{ backgroundColor: '#d49292', color: 'white', marginTop: '50px', marginLeft: '180px', height:'5vh', width:'30vw', borderRadius: '25px', minHeight: '50px', maxHeight: '70px', minWidth: '100px',maxWidth: '150px', bottom: '10%'}}  onClick={handleNo}>Non</button>
          </div>
        </div>
      </div>
      {showAlert && (
          <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={() =>
              {
                setShowAlert(false);
                goToForms(6);
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}
        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick} style={{ background:'grey' }}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>

    </div>
  );
};
export default VisualConfirmation;