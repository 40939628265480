import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import AlertMessage from '../AlertMessage';
import '../AlertMessage.css';
import MultiStepMenu from '../MultiStepMenu';

const Contraindications = ({ goToNextStep, goToFilterChildAge }) => {
  const [isValidInput, setIsValidInput] = useState(true);
  const [validationStatus, setValidationStatus] = useState(false);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [selectedLocationOption, setSelectedLocationOption] = useState('none');
  const [selectedGenderOption, setSelectedGenderOption] = useState('none');
  const [selectedAgeOption, setSelectedAgeOption] = useState('none');
  const [selectedPregnancyOption, setSelectedPregnancyOption] = useState('none');
  const [selectedAllergyOption, setSelectedAllergyOption] = useState('none');

  const childage = useSelector(state => state.childage);

  const handleLocationOptionClick = (value) => setSelectedLocationOption(value);

  // const handleGenderOptionClick = (value) => setSelectedGenderOption(value);
  const handleGenderOptionClick = (value) => {
    setSelectedGenderOption(value);
    if (value === "Masculin") {
      setSelectedPregnancyOption("Non");
    }
  };


  const handleAgeOptionClick = (value) => setSelectedAgeOption(value);
  const handlePregnancyOptionClick = (value) => setSelectedPregnancyOption(value);
  const handleAllergyOptionClick = (value) => setSelectedAllergyOption(value);

  const handlePrevClick = () => setShowAlert(true);

  const handleNextClick = () => {
    if (childage) {
      if (selectedAgeOption !== 'none' && selectedAllergyOption !== 'none') {
        setValidationStatus(true);
        dispatchAnswers();
        goToNextStep();
      } else {
        setValidationStatus(false);
        alert("Please fill out all required fields and submit the form before proceeding.");
      }
    } else {
      if (
        selectedAgeOption !== 'none' &&
        selectedPregnancyOption !== 'none' &&
        selectedAllergyOption !== 'none'
      ) {
        setValidationStatus(true);
        dispatchAnswers();
        goToNextStep();
      } else {
        setValidationStatus(false);
        setShowErrorAlert(true);
      }
    }
  };

  const dispatchAnswers = () => {
    dispatch({
      type: 'SAVE_CONTRAINDICATIONS',
      payload: {
        location: selectedLocationOption,
        gender: selectedGenderOption,
        age: selectedAgeOption,
        pregnancy: selectedPregnancyOption,
        allergy: selectedAllergyOption,
      },
    });
  };

  const handleConfirm = () => {
    setShowAlert(false); // Hide the alert
    dispatch({ type: 'SET_FLOW', payload:'backward'});
    goToFilterChildAge();
  }

  const handleCancel = () => setShowAlert(false)

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={1} numberOfSteps={6} />
      </div>

      <div className="form">
        {/* Location dropdown */}
          <h2 style={{marginTop: '10%'}}  >Où vivez-vous ?</h2>
          <div className="rounded-textbox">
            <select
              name="location"
              id="location"
              value={selectedLocationOption}
              onChange={(e) => handleLocationOptionClick(e.target.value)}
              style={{ width: '200px', height: '40px', borderRadius: '10px' }}
            >
              <option value="none" disabled hidden>
                Sélectionnez une option
              </option>
              <option value="Ville">Ville</option>
              <option value="Campagne">Campagne</option>
              <option value="Banlieue">Banlieue</option>
            </select>
          </div>

        {/* Gender dropdown */}
          <h2 style={{ marginTop: '5%' }}>Quel est votre sexe ?</h2>
          <div className="rounded-textbox">
            <select
              name="gender"
              id="gender"
              value={selectedGenderOption}
              onChange={(e) => handleGenderOptionClick(e.target.value)}
              style={{ width: '200px', height: '40px', borderRadius: '10px' }}
            >
              <option value="none" disabled hidden>
                Sélectionnez une option
              </option>
              <option value="Feminin">Feminin</option>
              <option value="Masculin">Masculin</option>
            </select>
          </div>

        {/* Age dropdown */}
          <h2 style={{ marginTop: '5%' }}>Sélectionnez la catégorie d'âge</h2>
          <div className="rounded-textbox">
            <select
              name="age"
              id="age"
              value={selectedAgeOption}
              onChange={(e) => handleAgeOptionClick(e.target.value)}
              style={{ width: '200px', height: '40px', borderRadius: '10px' }}
            >
              {childage ? (
                <>
                  <option value="none" disabled hidden>
                    Sélectionnez une option
                  </option>
                  <option value="0-1 an">Bébé (0-1 an)</option>
                  <option value="2-5 ans">Enfant (2-5 ans)</option>
                </>
              ) : (
                <>
                  <option value="none" disabled hidden>
                    Sélectionnez une option
                  </option>
                  <option value="6-12 ans">Enfant (6-12 ans)</option>
                  <option value="13-17 ans">Adolescent (13-17 ans)</option>
                  <option value="18-24 ans">Jeune adulte (18-24 ans)</option>
                  <option value="25-44 ans">Adulte (25-44 ans)</option>
                  <option value="45-64 ans">Adulte d'âge moyen (45-64 ans)</option>
                  <option value="65 ans et plus">Personnes âgées (65 ans et plus)</option>
                </>
              )}
            </select>
          </div>

        {!childage && (
          <div className="custom-form-group">
            <h2 style={{ marginTop: '5%' }}>Êtes-vous enceinte ou allaitez-vous ?</h2>
            <div className="rounded-textbox">
              <select
                name="pregnancy"
                id="pregnancy"
                value={selectedPregnancyOption}
                onChange={(e) => handlePregnancyOptionClick(e.target.value)}
                style={{ width: '200px', height: '40px', borderRadius: '10px' }}
              >
                <option value="none" disabled hidden>
                  Sélectionnez une option
                </option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </select>
            </div>
          </div>
        )}

        <div className="custom-form-group">
          <h2 style={{ marginTop: '5%' }}>Êtes-vous sujet(te) aux allergies ?</h2>
          <div className="rounded-textbox">
            <select
              name="allergy"
              id="allergy"
              value={selectedAllergyOption}
              onChange={(e) => handleAllergyOptionClick(e.target.value)}
              style={{ width: '200px', height: '40px', borderRadius: '10px' }}
            >
              <option value="none" disabled hidden>
                Sélectionnez une option
              </option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
        </div>

        <div className="footer">
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>

      {showAlert && (
        <AlertMessage
        message="Êtes-vous sûr de vouloir continuer ?"
        subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
        onConfirm={() =>
          handleConfirm()}
        onCancel={handleCancel}
      />
      )}

      {showErrorAlert && (
        <AlertMessage
          message="Veuillez remplir tous les champs obligatoires et soumettre le formulaire avant de continuer."
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

    </div>
  );
};

export default Contraindications;