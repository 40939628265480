import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';

const FilterProduct = ({ goToConsent, goToFilterChildAge }) => {
  const [selectedButton, setSelectedButton] = useState('');  // Only one button should be selected
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const categories = useSelector(state => state.categories);

  // Add "Not specific" at the start of the categories list
  const updatedCategories = [...categories.slice().sort((a, b) => a.localeCompare(b)), 'Aucun'];

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    if (selectedButton) {
      const payload = selectedButton === 'Aucun' ? 'All' : selectedButton;
      dispatch({ type: 'SELECTED_CATEGORY', payload });
      goToFilterChildAge();
      console.log('Next button clicked, and buttons are selected:', selectedButton);
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    // Set the selected button
    setSelectedButton(buttonText);  // Replace with the new selected button
  };


  return (
    <div className="container">
      <div className="fixed-header" style={{
        position: 'fixed',
        top: '10%',
        width: '100%',
        backgroundColor: 'white',
        padding: '20px 0',
        zIndex: 1,
        textAlign: 'center',
      }}>
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '20px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center'}}> Quel type de produit capillaire recherchez-vous ? </h2>
      </div>
      <div className="form" style={{marginTop: '20%'}}>
        <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {updatedCategories.map((buttonText) => (
            <button
              className="submit-button"
              key={buttonText}
              style={{
                backgroundColor: selectedButton === buttonText ? '#d49292' : 'black',
                margin: '5%',
                width: '80%',
                height: '10%',
                minWidth: '200px',
              }}
              onClick={(e) => {
                e.preventDefault();
                handleButtonClick(buttonText);
              }}
            >
              <span>{buttonText}</span>
            </button>
          ))}
        </form>
      </div>

      {showAlert && (
        <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowAlert(false);
            goToConsent();
          }
          }
          onCancel={() => {
            setShowAlert(false);
          }}
        />
      )}

      {showErrorAlert && (
        <AlertMessage
          message="Veuillez sélectionner une option avant de poursuivre."
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
    </div>
  );
};

export default FilterProduct;