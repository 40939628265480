import React, { useState } from 'react';
import googleLogoutImage from '../assets/logos/google_logout_button.png'; 
import { googleLogout } from '@react-oauth/google';
import { logoutProfile } from '../store';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';

const Logout = ({ goToLanding }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleLogoutClick = () => {
    setShowAlert(true); // Show confirmation alert
  };

  return (
    <div>
      {showAlert && (
      <AlertMessage
        message="Êtes-vous sûr de vouloir quitter cette page ?"
        subtext="Toute modification non enregistrée sera perdue."
        onConfirm={() => 
          {
            googleLogout();
            logoutProfile();
            goToLanding();
            setShowAlert(false);
          }} // Log out only if "Oui" is clicked
        onCancel={() => {
          setShowAlert(false);
      }}   // Close alert if "Non" is clicked
      />
    )}
    <div className='logout-button' onClick={handleLogoutClick}>
      <img src={googleLogoutImage} alt="Sign Out" className='logout-image'/>
    </div>
  </div>
  );
};

export default Logout;