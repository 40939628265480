import React, { useEffect, useState } from 'react';
import './App.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Header from './components/Header';
import Landing from './components/Landing';
import Consent from './components/Consent';
import MultiStepMenu from './components/MultiStepMenu';
import Forms from './components/Forms';
import Footer from './components/Footer';
import Terminate from './components/Terminate';
import Authorisation from './components/Authorisation';
import DisplayVideo from './components/DisplayVideo';
import FilterProduct from './components/FilterProduct';
import FilterChildAge from './components/FilterChildAge';
import Overview from './components/Overview';
import ProductMatchFalse from './components/ProductMatchFalse';
import ProductMatchTrue from './components/ProductMatchTrue';
import VisualConfirmation from './components/VisualConfirmation';
import HairSegmentation from './components/HairSegmentation';
import NoPage from './components/NoPage';
import YesPage from './components/YesPage';
import TellMeMorePage from './components/TellMeMorePage';
import RatingsPage from './components/RatingsPage';

// Define global variables
const IPINFO_TOKEN = '5e3edf78b69b41'; // related to ffptechdev@gmail.com account on https://ipinfo.io/developers

function App() {
  const numberOfSteps = 6; // Define the number of steps
  const [iplocation, setIPLocation] = useState(null); // Define IP location variable
  const [gpslocation, setGPSLocation] = useState(null); // Define GPS location variable
  const [step, setStep] = useState(1); // State of the multistep menu
  const [disableHeader, setDisableHeader] = useState(false);

  const state = {
    Landing: "landing",
    Consent: "consent",
    Forms: "forms",
    Terminate: "terminate",
    Authorisation: "authorisation",
    DisplayVideo: "displayvideo",
    FilterChildAge: "filterchildage",
    FilterProduct: "filterproduct",
    Overview: "overview",
    ProductMatchTrue: "productmatchtrue",
    ProductMatchFalse: "productmatchfalse",
    VisualConfirmation: "visualconfirmation",
    HairSegmentation: "hairsegmentation",
    NoPage: "nopage",
    YesPage: "yespage",
    TellMeMorePage: "tellmemorepage",
    RatingsPage: "ratingspage"
  }
  const [appstate, setAppState] = useState(state.Landing)

  // Add a useEffect to update disableHeader based on appstate
  useEffect(() => {
    // Check the current app state and update disableHeader accordingly
    if (appstate === state.Landing || appstate === state.Authorisation) {
      setDisableHeader(true);
    } else {
      setDisableHeader(false);
    }
  }, [appstate]);

  // function for fetching GPS location
  useEffect(() => {
    const fetchGPSLocation = async () => {
      navigator.geolocation.getCurrentPosition(
        (data) => {
          setGPSLocation({
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
          });
        },
        (error) => {
          console.error('Error fetching GPS location: ', error);
        },
        { timeout: 1500 } // timeout
      );
      console.log('GPS Location: ' + gpslocation);
    };
    fetchGPSLocation();
  }, []);

  // function for fetching IP location
  useEffect(() => {
    const fetchIPLocation = async () => {
      try {
        const response = await fetch('https://ipinfo.io?token=' + IPINFO_TOKEN);
        const data = await response.json();
        setIPLocation(data);
        console.log('IP Location: ' + iplocation);
      } catch(error) {
        console.error('Error fetching IP location: ', error);
      }
    };
    fetchIPLocation();
  }, []);

  // function for setting step
  const goToStep = (newStep) => {
    setStep(newStep);
  };

  // function for navigating to the next step
  const goToNextStep = () => {
  if (step < numberOfSteps) {
    setStep(step + 1);
  }
  };

  // function for navigating to the previous step
  const goToPrevStep = () => {
  if (step > 1) {
    setStep(step - 1);
  }
  };

  const options = {
    title: '#Halisi',
    message: 'Are you sure you want to leave this page? Any unsaved changes will be lost.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name"
  };

  const goToLanding = () => {
    // alert('Are you sure you want to leave this page? Any unsaved changes will be lost.');
    // confirmAlert(options);
    setStep(1);
    setAppState(state.Landing);
  };

  const goToConsent = () => {
    setStep(1);
    setAppState(state.Consent);
  };

  const goToForms = (stepnum) => {
    setStep(stepnum);
    setAppState(state.Forms);
  };

  const goToAuthorisation = () => {
    setStep(1);
    setAppState(state.Authorisation);
  };

  const goToDisplayVideo = () => {
    setStep(1);
    setAppState(state.DisplayVideo);
  };

  const goToTerminate = () => {
    setStep(1);
    setAppState(state.Terminate);
  };

  const goToFilterChildAge = () => {
    setStep(1);
    setAppState(state.FilterChildAge);
  };

  const goToFilterProduct = () => {
    setStep(1);
    setAppState(state.FilterProduct);
  };
  const goToOverview = () => {
    setStep(1);
    setAppState(state.Overview);
  };
  const goToProductMatchFalse = () => {
    setStep(1);
    setAppState(state.ProductMatchFalse);
  };
  const goToProductMatchTrue = () => {
    setStep(1);
    setAppState(state.ProductMatchTrue);
  };

  const goToVisualConfirmation = () => {
    setStep(1);
    setAppState(state.VisualConfirmation);
  };

  const goToHairSegmentation = () => {
    setStep(1);
    setAppState(state.HairSegmentation);
  };

  const goToNoPage = () => {
    setStep(1);
    setAppState(state.NoPage);
  };

  const goToYesPage = () => {
    setStep(1);
    setAppState(state.YesPage);
  };

  const goToTellMeMore = () => {
    setStep(1);
    setAppState(state.TellMeMorePage);
  };

  const goToRatingsPage = () => {
    setStep(1);
    setAppState(state.RatingsPage);
  };

  return (
    <div className="App">
      <Header goToDisplayVideo={goToDisplayVideo} disableHeader={disableHeader} goToLanding={goToLanding}/>
      {appstate === state.Landing && <Landing goToAuthorisation={goToAuthorisation} /> }
      {appstate === state.Authorisation && <Authorisation goToDisplayVideo={goToDisplayVideo} goToLanding={goToLanding} /> }
      {appstate === state.DisplayVideo && <DisplayVideo goToConsent={goToConsent} />}
      {appstate === state.Consent && <Consent goToFilterProduct={goToFilterProduct} />}
      {appstate === state.FilterProduct && <FilterProduct goToConsent={goToConsent} goToFilterChildAge={goToFilterChildAge} />}
      {appstate === state.FilterChildAge && <FilterChildAge goToFilterProduct={goToFilterProduct} goToForms={goToForms} />}
      {appstate === state.Forms && (
        <div className="forms-wrapper">
          <div className="fixed-header" >
            {/* <MultiStepMenu step={step} goToStep={goToStep} numberOfSteps={numberOfSteps} /> */}
          </div>
          <Forms step={step} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} goToFilterChildAge={goToFilterChildAge} goToVisualConfirmation={goToVisualConfirmation}/>
        </div>
      )}
      {appstate === state.VisualConfirmation && <VisualConfirmation goToOverview={goToOverview} goToHairSegmentation={goToHairSegmentation} goToForms={goToForms} />}
      {appstate === state.HairSegmentation && <HairSegmentation goToVisualConfirmation={goToVisualConfirmation} goToOverview={goToOverview}  />}

      {appstate === state.Terminate && <Terminate goToLanding={goToLanding} />}
      {appstate === state.Overview && <Overview goToHairSegmentation={goToHairSegmentation} goToProductMatchFalse={goToProductMatchFalse} goToProductMatchTrue={goToProductMatchTrue} />}
      {appstate === state.ProductMatchFalse && <ProductMatchFalse goToDisplayVideo={goToDisplayVideo}/>}
      {appstate === state.ProductMatchTrue && <ProductMatchTrue goToNoPage={goToNoPage}  goToTellMeMore={goToTellMeMore} goToYesPage={goToYesPage} />}
      {appstate === state.NoPage && <NoPage goToRatingsPage={goToRatingsPage} />}
      {appstate === state.YesPage && <YesPage goToRatingsPage={goToRatingsPage} />}
      {appstate === state.TellMeMorePage && <TellMeMorePage goToNoPage={goToNoPage} goToProductMatchTrue={goToProductMatchTrue} goToYesPage={goToYesPage} />}
      {appstate === state.RatingsPage && <RatingsPage goToDisplayVideo={goToDisplayVideo} goToLanding={goToLanding} />}
      </div>
  );
}

export default App;