import React, { useState } from 'react';
import '../App.css';
import { useDispatch } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';

const FilterChildAge = ({ goToFilterProduct, goToForms }) => {
  const [isChecked, setIsChecked] = useState(null); // Changed to null to handle both yes and no
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [successfulValidation, setSuccessfulValidation] = useState(false);


  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    if (isChecked !== null) { // Ensure that a selection has been made
      dispatch({ type: 'SAVE_CHILD_AGE_RESPONSE', payload: isChecked });
      goToForms(1);
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleYesClick = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsChecked(isChecked === true ? null : true); // Toggle selection
  };

  const handleNoClick = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsChecked(isChecked === false ? null : false); // Toggle selection
  };

  const handleConfirm = () => {
    setShowAlert(false);
    goToFilterProduct();
  }

  const handleCancel = () => setShowAlert(false)

  return (
    <div className="container">
      <div className="form">
      <h2 style={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '10px', zIndex: 1, fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', marginTop: '10%', textAlign: 'center'}}>Besoin d'un produit pour un enfant de moins de 5 ans ?​</h2>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10%',
          }}
        >
          <button
            className="submit-button"
            onClick={handleYesClick}
            style={{
              margin: '5%',
              backgroundColor: isChecked === true ? '#d49292' : 'black', // Green if selected
              margin: '5%',
              width: '80%',
              height: '10%',
              minWidth: '200px'
            }}
          >
            <span>Oui</span>
          </button>
          <button
            className="submit-button"
            onClick={handleNoClick}
            style={{
              margin: '5%',
              backgroundColor: isChecked === false ? '#d49292' : 'black', // Green if selected
              margin: '5%',
              width: '80%',
              height: '10%',
              minWidth: '200px'
            }}
          >
            <span>Non</span>
          </button>
        </form>
        {/* <div className='submit-container'>
          <div className="white-banner">
            <button className="submit-button-fixed" onClick={handleSubmit}>Soumettre​</button>
          </div>
        </div> */}
      </div>
      {showAlert && (
        <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}


      {showErrorAlert && (
        <AlertMessage
          message="Veuillez sélectionner Oui ou Non avant de continuer."
           onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
    </div>
  );
};

export default FilterChildAge;
