import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';
import texture1A from '../../assets/logos/1A.png';
import texture1B from '../../assets/logos/1B.png';
import texture1C from '../../assets/logos/1C.png';
import texture2A from '../../assets/logos/2A.png';
import texture2B from '../../assets/logos/2B.png';
import texture2C from '../../assets/logos/2C.png';
import texture3A from '../../assets/logos/3A.png';
import texture3B from '../../assets/logos/3B.png';
import texture3C from '../../assets/logos/3C.png';
import texture4A from '../../assets/logos/4A.png';
import texture4B from '../../assets/logos/4B.png';
import texture4C from '../../assets/logos/4C.png';
import '../AlertMessage.css';


const HairClass = ({ goToNextStep, goToPrevStep }) => {
  const [selectedHairClass, setSelectedHairClass] = useState(null); // Save hair class identifier (e.g., '1A', '2B')
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Array mapping textures to their corresponding hair class identifier
  const hairTextures = [
    { id: '1A', img: texture1A },
    { id: '1B', img: texture1B },
    { id: '1C', img: texture1C },
    { id: '2A', img: texture2A },
    { id: '2B', img: texture2B },
    { id: '2C', img: texture2C },
    { id: '3A', img: texture3A },
    { id: '3B', img: texture3B },
    { id: '3C', img: texture3C },
    { id: '4A', img: texture4A },
    { id: '4B', img: texture4B },
    { id: '4C', img: texture4C },
  ];

  // Handle image click to save the hair class identifier
  const handleImageClick = (hairClassId) => {
    setSelectedHairClass(selectedHairClass === hairClassId ? null : hairClassId); // Toggle selection
  };

  const renderLoadingSpinner = () => {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner-camera"></div>
      </div>
    );
  };

  const handlePrevClick = () => {
    setShowAlert(true);
  };


  const handleNextClick = () => {
    if (selectedHairClass) {
      // Dispatch the selected hair class identifier to the Redux store
      dispatch({ type: 'SAVED_HAIR_CLASS', payload: selectedHairClass });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };


  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={2} numberOfSteps={6} />
        <h2 style={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '10px', zIndex: 1, fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', marginTop: '10%', textAlign: 'center'}}>
            Quelle est votre texture de cheveux naturelle ?
          </h2>
      </div>

        <div className="form" style={{marginTop: '20%'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500px', overflowY: 'auto',  scrollbarWidth: 'none',  msOverflowStyle: 'none' }}>
            {hairTextures.map((texture, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(texture.id)} // Save the hair class identifier (e.g., '1A', '1B')
                style={{
                  border: selectedHairClass === texture.id ? '2px solid blue' : 'none', // Apply blue border if selected
                  marginBottom: '10px',
                  cursor: 'pointer',
                  display: 'inline-block' // Keep the size of the border within the div
                }}
              >
                <img
                  src={texture.img}
                  alt={`Texture de cheveux ${texture.id}`}
                  width={250}
                  height={250}
                  className="scrollable-image"
                />
              </div>
            ))}
          </div>
        </div>

        {showAlert && (
          <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={() =>
              {
                setShowAlert(false);
                goToPrevStep();
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}

        {showErrorAlert && (
        <AlertMessage
          message="Veuillez sélectionner le type de cheveux approprié."
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    // </div>
  );
};

export default HairClass;