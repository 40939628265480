import React, { useState } from 'react';
import '../../App.css';
import { useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';
import '../AlertMessage.css';

const HairState = ({ goToNextStep, goToPrevStep }) => {
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [multipleSelectionAlert, setMultipleSelectionAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);


  const handlePrevClick = () => {
    setShowAlert(true);
};

  const handleNextClick = () => {
    if (selectedButton) {
      dispatch({ type: 'SAVED_HAIR_STATE', payload: selectedButton });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    if (selectedButton === buttonText) {
      // Désélectionner le bouton si déjà sélectionné
      setSelectedButton(null);
      setMultipleSelectionAlert(false);
    } else {
      // Sélectionner le nouveau bouton
      setSelectedButton(buttonText);
      setMultipleSelectionAlert(false);
    }
  };

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={3} numberOfSteps={6} />
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '10px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center'}}>
            Quel est l'état actuel de vos cheveux ?
          </h2>
      </div>
      <div className="form" style={{marginTop: '25%'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500px', overflowY: 'auto',  scrollbarWidth: 'none',  msOverflowStyle: 'none'  }}>
            {['Défrisés/traités chimiquement', 'En transition', 'Locs', 'Naturels', 'Tresses'].map((item) => (
              <button
                className="submit-button"
                key={item}
                style={{
                  margin: '5%',
                  backgroundColor: selectedButton === item ? '#d49292' : 'black',
                  // width: '80%',
                  height: '10%',
                  width: '250px'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleButtonClick(item);
                }}
              >
                <span>{item}</span>
              </button>
            ))}
          </div>
          {multipleSelectionAlert && (
            <p style={{ color: 'red' }}>
              Veuillez sélectionner qu'un seul bouton à la fois.
            </p>
          )}
        </div>

        {showAlert && (
          <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={() =>
              {
                setShowAlert(false);
                goToPrevStep();
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}

        {showErrorAlert && (
        <AlertMessage
          message="Veuillez sélectionner l'état des cheveux approprié."
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}
        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    // </div>
  );
};

export default HairState;