import React, {useState} from 'react';
import '../App.css';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import axios from 'axios';
import AlertMessage from './AlertMessage';

const Consent = ({ goToFilterProduct }) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch(); // Initialize dispatch function
  const [isSubmit, setIsSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  
  const handleNextClick = () => {
    if (isChecked) {
      // Call navigation function here
      // getProductCategories();
      dispatch({ type: 'SET_CONSENT_FLAG', payload: true}); 
      goToFilterProduct();
      
    } else {
      // Show alert if isChecked is false
      // alert('Pour continuer à utiliser Halisi, veuillez consulter et accepter nos termes et conditions.');
      setShowAlert(true);
    }
  };

  // const getProductCategories = () => {
  //   setApiCallInProgress(true);
  //   setIsSubmit(true);
  //   let data =
  //     {language: "FR"};
  //     axios
  //       .post("https://hal-cos-sibca-prd-gwc-fnapp-v1.azurewebsites.net/api/readproductcategories", data)
  //                   .then((data) => {
  //                       let resp = data.data;
  //                       setApiCallInProgress(false);
  //                       dispatch({ type: 'SET_PRODUCT_CATEGORIES', payload: resp['product_categories']}); // Dispatch action to save res object 
  //                   }
  //                 )};

  return (
    <div className="consent">
      <div className="content">
        <p className="responsive-text">
          En cochant cette case, vous acceptez que les données collectées par l'application, uniquement sur l'état de vos cheveux et sans inclure d'informations personnelles, soient utilisées pour des statistiques d'étude de marché. Ces données seront traitées conformément au RGPD (article 6, paragraphe 1, point f) - Intérêts légitimes.
        </p>
        <div className="checkbox-container">
          <input type="checkbox" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
          <label className="responsive-label">Je suis d'accord</label>
        </div>
      </div>
      <div className="footer">
        <button onClick={handleNextClick} disabled={true}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
      {/* Conditionally render AlertMessage */}
      {showAlert && (
        <AlertMessage
          message="Pour continuer à utiliser Halisi, veuillez consulter et accepter nos termes et conditions."
          onConfirm={() => setShowAlert(false)} // Dismiss the alert on confirmation
        />
      )}
    </div>
  );
};

export default Consent;
