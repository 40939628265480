import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'; // Import Provider
import store from './store';

ReactDOM.render(
  <Provider store={store}> {/* Wrap your root component with Provider and pass the Redux store */}
  <App />
  </Provider>,
document.getElementById("root")
);