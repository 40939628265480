import React, { useState, useEffect } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'; // Assuming you will use axios for API calls
import { clearDiagnosticsLLM, clearProductLLM, clearSavedProduct} from '../store';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const TellMeMorePage = ({ goToNoPage, goToProductMatchTrue, goToYesPage }) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const [showDataAlert, setShowDataAlert] = useState(false); // State for data confirmation alert
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  const [downloadingPDF, setDownloadingPDF] = useState(false); // State to track PDF downloading
  const [enableButton, setEnableButton] = useState(false); // State to enable/disable buttons
  const [productDetails, setProductDetails] = useState(null); // State for product details
  const [productImage, setProductImage] = useState(null); // State for product image
  const savedProduct = useSelector(state => state.savedProduct);
  const productLLMText = useSelector(state => state.productLLMText);

  const handlePasEncore = () => {
    goToNoPage();
  };

  const handleTellMeMore = () => {
    dispatch(clearProductLLM());
    dispatch(clearSavedProduct());
    goToProductMatchTrue();
  }

  const handlePret = () => {
    setApiCallInProgress(true);
    let data = {language: "FR"};
    axios.post("https://hal-cos-sibca-prd-gwc-fnapp-v1.azurewebsites.net/api/readticketnumber", data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then((response) => {
        const resp = response.data;
        setApiCallInProgress(false);
        console.log('API response:', resp);
        dispatch({ type: 'TICKET_NUMBER', payload: resp['ticket_number_display'] });
        goToYesPage();
      })
      .catch((error) => {
        setApiCallInProgress(false); // Stop spinner
        console.error("Error during API request:", error);
        alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
      });
  };

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Header */}
        <h2 style={{ fontSize: '2.0rem' }}>Découvrez votre produit capillaire idéal!​​</h2>

        {/* Image and more text side by side */}
        <div style={{ display: 'flex', marginTop: '5%', width: '100%', justifyContent: 'center' }}>
          {/* Image on the left */}
          <div style={{ width: '40%', textAlign: 'center' }}>
            {savedProduct?.imageUrl ? (
              <img src={savedProduct.imageUrl} alt="Product" style={{ maxWidth: '100%', height: '100%' }} />
            ) : (
              <div style={{ width: '100px', height: '100px', backgroundColor: 'lightgray' }}></div>
            )}
          </div>

          {/* Text section on the right */}
          <div
            style={{
              fontSize: '1.0rem',
              textAlign: 'left',
              width: '60%',
              padding: '0 10px' // Ensure content does not touch screen edges
            }}
          >
            {productLLMText.split('\n').map((line, index) => {
              if (line.includes("Avantages du produit")) { // Only display this section
                const [header, ...rest] = line.split(':');
                const content = rest.join(':').trim();
                return (
                  <React.Fragment key={index}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2rem', textTransform: 'uppercase' }}>
                      {header}
                    </span>
                    <span style={{ display: 'block'}}>
                      {content}
                    </span>
                    <br />
                  </React.Fragment>
                );
              }
              return null; // Skip lines that don't include "Avantages du produit"
            })}
          </div>
      </div>

      {/* Product Price */}
      <h2 style={{ marginTop: '2%', fontSize: '1.8rem', color: '#d49292' }}>
        {savedProduct?.price ? `${savedProduct.price} €` : "Price not available"}
      </h2>

      {/* Product Quantity */}
      <h2 style={{ marginTop: '0%', fontSize: '1.2rem', color: 'black' }}>
        {savedProduct?.quantity ? savedProduct.quantity : "Quantity not available"}
      </h2>
        
        {/* Êtes-vous prêt(e) section */}
        <h2 style={{ marginTop: '10%', fontWeight: 'bold' }}>Êtes-vous prêt(e) à récupérer votre produit?​​</h2>

        {/* Buttons */}
        <div className='submit-container'>
          <div className="white-banner">
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%',  marginTop: '-5%'}}>
              <button onClick={handlePasEncore} style={{ backgroundColor: 'black', color: 'white', padding: '10px 20px', borderRadius: '20px', fontSize: '3.3vw', height: '60px',  fontWeight: 'bold', textAlign: 'center', cursor: 'pointer', bottom: '150px', }}>
                Non
              </button>
              <button onClick={handleTellMeMore} style={{ backgroundColor: 'black', color: 'white', padding: '10px 20px', borderRadius: '20px', fontSize: '3.3vw', height: '60px',  fontWeight: 'bold', textAlign: 'center', cursor: 'pointer'  }}>
                Aller aux produits
              </button>
              <button onClick={handlePret} style={{ backgroundColor: '#d49292', color: 'white', padding: '10px 20px', borderRadius: '20px', fontSize: '3.3vw', height: '60px',  fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}>
                Oui
              </button>
            </div>
          </div>
        </div>
        <div
            className="footer hide-on-print"
            style={{
              backgroundColor: 'white',
              borderColor: 'white',
              boxShadow: 'none'
            }}
          >
            {/* <p className="footer-text">
              Les recommandations de produits sont fournies à titre informatif uniquement. <br/>
              Neotex décline toute responsabilité quant à leur utilisation ou à leurs résultats.
            </p> */}
          </div>
      </div>
    </div>
  );
};

export default TellMeMorePage;