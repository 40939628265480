import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import productLogo from '../assets/logos/product_logo.png'; // Import your logo file
import Login from './Login';
import {GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch , useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';
import axios from 'axios';

const Authorisation = ({ goToLanding, goToDisplayVideo }) => {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const apiStatus = useSelector(state => state.apiStatus);
  const dispatch = useDispatch();
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  const renderLoadingSpinner = () => {
    return (
      <div className="loading-spinner-container-auth">
        <div className="loading-spinner-auth"></div>
      </div>
    );
  };

  const getProductCategories = () => {
    setApiCallInProgress(true);
    setIsSubmit(true);
    let data =
      {language: "FR"};
      axios
        .post("https://hal-cos-sibca-prd-gwc-fnapp-v1.azurewebsites.net/api/readproductcategories", data)
                    .then((data) => {
                        let resp = data.data;
                        setApiCallInProgress(false);
                        dispatch({ type: 'SET_PRODUCT_CATEGORIES', payload: resp['product_categories']}); // Dispatch action to save res object 
                    }
                  )};

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleNextClick = async () => {
    // Call the first function
    getProductCategories();
    // Wait for 2 seconds
    await sleep(1000);
    // Call the second function
    goToDisplayVideo();
  };

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleGoogleLoginFailure = () => {
    // Handle the Google authentication response here
    setShowFailureAlert(true);
  };

  const handleGoogleLoginSuccess = () => {
    // Handle the Google authentication response here
    setShowSuccessAlert(true);
  };

  const handleConfirm = () => {
      setShowAlert(false); // Hide the alert
      goToLanding();
    }

  const handleCancel = () => setShowAlert(false)

  return (
    <div className="landing">
      {/* Company logo */}
      <div className="logo-container">
        <img src={productLogo} alt="Product Logo" className="logo" />
      </div>
      <div className="google-container">
        <p className="welcome-back">Content de vous revoir!</p>
        {/* Google Login button */}
        <GoogleOAuthProvider clientId="275717996013-bndc08evgidtut5c6pf6rmt51j0csl9j.apps.googleusercontent.com">
          <Login handleGoogleLoginSuccess={handleGoogleLoginSuccess} handleGoogleLoginFailure={handleGoogleLoginFailure}></Login>
        </GoogleOAuthProvider>
        <div className='spinner'>
          {apiStatus && renderLoadingSpinner()}
        </div>
        {/* Navigation buttons */}
      </div>

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
      {/* Render the alert message if showAlert is true */}
      {showAlert && (
        <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      {showLoginAlert && (
        <AlertMessage
          message="Veuillez vous connecter avec votre compte Google pour continuer à accéder aux services Halisi."
          onConfirm={() => {
            setShowLoginAlert(false);
        }}
        />
      )}
      
      {showFailureAlert && (
        <AlertMessage
          message="Veuillez vérifier vos identifiants de connexion et réessayer."
          onConfirm={() => {
            setShowFailureAlert(false);
        }}
        />
      )}
      {showSuccessAlert && (
        <AlertMessage
          message="Bienvenue ! Vous êtes authentifié et prêt à commencer. N’hésitez pas à continuer."
          onConfirm={() => {
            setShowSuccessAlert(false);
            goToDisplayVideo();
        }}
        />
      )}
    </div>
  );
};


export default Authorisation;